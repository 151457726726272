<template>
  <v-app :style="colorVapp">
    <!-- :quicksearch="true" :parentfolder="parentfolder" @inputdata="filteredItems" -->
    <onebox_toolbar></onebox_toolbar>
    <v-content>
      <!-- Package -->
      <section class="white" style="color:black">
        <v-container fluid>
          <v-card class="mx-auto" outlined>
            <v-layout>
              <v-row align="center">
                <v-col class="text-h6" cols="6">
                  ปัจจุบันคุณมีพื้นที่เก็บข้อมูลทั้งหมด 100 GB
                </v-col>
                <v-col cols="6">
                  
                </v-col>
              </v-row>
            </v-layout>
          </v-card>
          <!-- <v-container grid-list-xs>
            <h2 class="text-center mt-10 mb-1" style="fornt-size:25">
              เลือกแพ็กเกจ One Box ที่เหมาะกับคุณ แพ็กเกจสมาชิกเริ่มต้นที่ ฿300 / เดือน
            </h2>
          </v-container> -->
          <v-layout align-center justify-center>
            <v-flex lg11>
              <swiper class="swiper" :options="swiperOption" ref="mySwiper">
                <!-- <swiper-slide>
                  <v-card hover outlined>
                    <v-layout justify-center class="text-center">
                      <v-card-title class=" mt-10" :style="header">ฟรี</v-card-title>
                      <br />
                    </v-layout>
                    <v-layout justify-center>
                      <v-card-title :style="headergb">1 GB</v-card-title>
                    </v-layout>
                    <br />
                  </v-card>
                </swiper-slide> -->
                <swiper-slide v-for="(item, text) in listpackage" :key="text">
                  <v-card outlined :color="item.package_color" :dark="item.dark" elevation="2">
                    <v-layout justify-center class="text-center">
                      <v-card-title class=" mt-10" :style="header">{{ item.package_name_th }}</v-card-title>
                      <br />
                    </v-layout>
                    <v-layout justify-center>
                      <v-card-title :style="headergb">{{ item.package_storage }}</v-card-title>
                    </v-layout>
                    <br />
                    <br />
                    <v-list-item-subtitle class="text-center"
                      ><v-icon small>people_alt</v-icon> {{ item.package_number_user }} User</v-list-item-subtitle
                    >
                    <br />
                    <v-layout justify-center>
                      <v-btn
                        color="primary"
                        :disabled="item.check_disable"
                        @click="fn_buypackage(item)"
                        v-if="item.check_disable == true"
                        >แพ็คเก็จปัจจุบันของคุณ</v-btn
                      >
                      <v-btn color="primary" :disabled="item.check_disable" @click="fn_buypackage(item, 'main')" v-else
                        >฿{{ item.package_price }} /เดือน</v-btn
                      >
                    </v-layout>
                    <br />
                    <v-layout justify-center>
                      <p>หรือชำระล่วงหน้าแบบรายปี</p>
                      <br />
                    </v-layout>
                    <v-layout justify-center>
                      <p>(ประหยัด 17%):</p>
                    </v-layout>

                    <v-layout justify-center>
                      <v-btn elevation="0" :color="item.package_color" :dark="item.dark" @click="fn_buypackage(item, 'main')"
                        >฿2,000/ปี</v-btn
                      >
                    </v-layout>
                    <br />
                    <br />
                    <v-divider class="mb-1"></v-divider>
                    <br />
                    <v-list class="ml-2" :color="item.package_color">
                      <v-list-item>
                        <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Manage User</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Upload/Download/Share</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Restrict Access</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Advance Search</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Connect Through API</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title> Send File to e-Mail</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <br />
                    <br />
                  </v-card>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <section class="blue lighten-5 hidden-sm-and-down" style="color:#1A237E">
        <v-divider></v-divider>
        <v-container fluid>
          <v-container grid-list-xs>
            <v-layout row wrap align-center justify-center>
              <v-row justify="center">
                <v-img :src="require('@/assets/package.png')" max-width="200" max-height="300" />
              </v-row>
            </v-layout>
            <h2 class="text-center mt-10 mb-1" style="fornt-size:25">
              แพ็กเกจเสริม Onebox เพื่อคุณ
            </h2>
            <v-card-text class="text-center mb-5" color="white">
              บัญชี One Box สามารถเลือกซื้อแพ็คเก็จเสริมเพื่อรองรับความต้องการของคุณ
            </v-card-text>
          </v-container>
          <v-layout row wrap align-center justify-center>
            <v-flex lg11>
              <v-row no-gutters align-center justify-center>
                <v-col v-for="(item, text) in listpackage_addon" :key="text" cols="12" sm="4" class="pa-4">
                  <v-card outlined elevation="2" color="#F5F5F5">
                    <v-layout justify-center class="text-center pa-3">
                      <v-card-title class=" mt-10" :style="header">{{
                        item.package_storage.split(" ")[1] === "GB" || item.package_storage.split(" ")[1] === "TB"
                          ? "Storage"
                          : "User"
                      }}</v-card-title>
                      <br />
                    </v-layout>
                    <v-layout justify-center>
                      <v-card-title :style="headergb">{{
                        item.package_storage.split(" ")[1] === "GB" || item.package_storage.split(" ")[1] === "TB"
                          ? item.package_storage
                          : item.package_number_user + "user"
                      }}</v-card-title>
                    </v-layout>
                    <v-layout justify-center>
                      <h4 class="text-center" style="color:#0033CC">{{ item.package_name_th }}</h4>
                    </v-layout>
                    <br />
                    <br />
                    <!-- <v-list-item-subtitle class="text-center"><v-icon small>people_alt</v-icon> {{item.package_number_user}} User</v-list-item-subtitle>
                      <br /> -->
                    <v-layout justify-center>
                      <!-- <v-btn color="primary" :disabled="item.check_disable" @click="fn_buypackage(item)" v-if="item.check_disable == true">แพ็คเก็จปัจจุบันของคุณ</v-btn> -->
                      <v-btn color="#F0F8FF" @click="fn_buypackage(item, 'add_on')">฿{{ item.package_price }}/เดือน</v-btn>
                    </v-layout>
                    <br />
                    <v-divider class="mb-1"></v-divider>
                    <br />
                    <v-list class="ml-2" color="#F5F5F5">
                      <v-list-item>
                        <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-if="item.package_storage.split(' ')[1] === 'GB' || item.package_storage.split(' ')[1] === 'TB'"
                            >Manage Storage</v-list-item-title
                          >
                          <v-list-item-title v-else>Manage User</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item>
                        <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Upload/Download/Share</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Restrict Access</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Advance Search</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Connect Through API</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title> Send File to e-Mail</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list>
                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
          <br />
          <br />
        </v-container>
      </section>
      <!-- ข้อมูลstorage -->
      <section class="white" style="color:black">
        <!-- <v-card class="elevation-3"> -->
        <v-container>
          <v-container grid-list-xs>
            <v-layout row wrap align-center justify-center>
              <v-row justify="center">
                <v-img :src="require('@/assets/landinglogo.png')" max-width="200" max-height="300" />
              </v-row>
            </v-layout>
            <h2 class="text-center mt-10 mb-10">
              คุณมีพื้นที่เก็บข้อมูลฟรี 2 GB
            </h2>
            <!-- <div class="text-center">
              <v-btn class="ma-2" dark outlined color="indigo">รับพื้นที่ข้อมูลเพิ่ม</v-btn>
            </div> -->
          </v-container>
          <br />
          <v-card class="mx-auto" max-width="600" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-card-text class="text-center mb-5" color="white">
                  พื้นที่เก็บข้อมูลรวม
                </v-card-text>
                <v-list-item-title class="text-h5 mb-1">
                  พื้นที่เก็บข้อมูลฟรี
                </v-list-item-title>
                <br />
                <v-divider class="mb-1"></v-divider>
                <br />
                <v-list-item-title class="text-h5 mb-1">
                  รวม
                </v-list-item-title>
                <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
            <br />
            <v-card-text class="text-center mb-5" color="white">
              ข้อมูลเพิ่มเติมเกี่ยวกับพื้นที่เก็บข้อมูล เมื่อเริ่มใช้งาน บัญชี Onebox ของคุณจะมีพื้นที่เก็บข้อมูลฟรี 2 GB
              โดยอัตโนมัติ
            </v-card-text>
            <!-- <v-card-text class="text-center" color="white">
               เมื่อเริ่มใช้งาน บัญชี Onebox ของคุณจะมีพื้นที่เก็บข้อมูลฟรี 2 GB โดยอัตโนมัติ
            </v-card-text> -->
          </v-card>
          <br />
          <br />
          <v-row no-gutters>
            <v-col class=" pa-5" cols="12" md="12">
              <v-row>
                <v-col cols="12">
                  <v-container grid-list-xs>
                    <!-- <h2>ใช้ไป {{storage_usage }} จาก {{showDataStorage}}</h2> -->
                    <br />
                    <!-- <v-card-text class="text-left mb-5">
                        ใช้ไป {{storage_usage }} จาก {{showDataStorage}}
                      </v-card-text> -->
                    <v-progress-linear
                      class="mt-2"
                      :color="percentagestoage_setcolor"
                      striped
                      rounded
                      height="10"
                      :value="percentageStorage"
                    ></v-progress-linear>
                    <br />
                    <v-card-text class="text-center mb-5"> ใช้ไป {{ storage_usage }} จาก {{ showDataStorage }} </v-card-text>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br />
          <br />
        </v-container>
        <!-- </v-card> -->
      </section>
    </v-content>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { slider, slideritem } from "vue-concise-slider";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: () => ({
    listpackage_addon: [],
    id_mypackage: "",
    listpackage: [],
    mypackage: [],
    listpackage_total: [],
    listpackage_current: [],
    URL_payment: "",
    // datapackage: [
    //   {
    //     text: "ฟรี",
    //     align: "center",
    //     storage: "500 MB",
    //     users: "10 Users",
    //     price: "แพ็คเกจปัจจุบัน",
    //     color: "",
    //     dark: false,
    //     bn_buy: true,
    //   },
    //   {
    //     text: "EXtra Small",
    //     align: "center",
    //     storage: "20 GB",
    //     users: "10 Users",
    //     price: "฿300 /เดือน",
    //     color: "#D9D9D9",
    //     dark: false,
    //     bn_buy: false,
    //   },
    //   {
    //     text: "Small",
    //     align: "center",
    //     storage: "50 GB",
    //     users: "10 Users",
    //     price: "฿400 /เดือน",
    //     color: "#408298",
    //     dark: true,
    //     bn_buy: false,
    //   },
    //   {
    //     text: "Mediun",
    //     align: "center",
    //     storage: "100 GB",
    //     users: "20 Users",
    //     price: "฿700 /เดือน",
    //     color: "#3C6E6F",
    //     dark: true,
    //     bn_buy: false,
    //   },
    //   {
    //     text: "Large",
    //     align: "center",
    //     storage: "1 TB",
    //     users: "100 Users",
    //     price: "฿4,800 /เดือน",
    //     color: "#274F63",
    //     dark: true,
    //     bn_buy: false,
    //   },
    //   {
    //     text: "Extra Large",
    //     align: "center",
    //     storage: "5 TB",
    //     users: "100 Users",
    //     price: "฿16,000 /เดือน",
    //     color: "#353535",
    //     dark: true,
    //     bn_buy: false,
    //   },
    // ],

    swiperOption: {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    isFooterDesktop: true,
    isFooterMobile: false,
    showDataStorage: "",
    // percentageStorage: 90,
    // percentagestoage_setcolor:"red"
  }),
  components: {
    InfiniteLoading,
    VueFullScreenFileDrop,
    onebox_toolbar,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "storage_usage"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headergb() {
      return "font-size: 40px";
    },
    header() {
      return "font-size: 30px";
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    percentagestoage_setcolor() {
      if (Math.ceil(this.percentageStorage) >= 0 && Math.ceil(this.percentageStorage) < 70) {
        return "primary";
      } else if (Math.ceil(this.percentageStorage) >= 70 && Math.ceil(this.percentageStorage) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStorage) >= 90) {
        return "red";
      }
    },
    percentageStorage() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage) / this.convertstorageToByte(this.dataStorageMax)) * 100;

      self.showDataStorage = self.dataStorageMax;
      // console.log("percentageStorage",percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage));
      return percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
    },
    _oldpercentageStorage() {
      let self = this;
      let _dataStorageMax = [];
      // console.log("tttt", this.dataStorageMax);

      // if(){}
      let datastorage_max = this.dataStorageMax.split(" ");
      // console.log("datastorage_max", datastorage_max);

      if (datastorage_max[1] === "KB") {
        if (datastorage_max[0] / 1048576 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1048576));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0] / 1048576).toFixed(2)) + " " + "GB";
        } else {
          _dataStorageMax.push(String(datastorage_max[0]));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "KB";
        }
      } else if (datastorage_max[1] === "MB") {
        if (datastorage_max[0] / 1024 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1024));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0] / 1024).toFixed(2)) + " " + "GB";
        } else {
          _dataStorageMax = this.dataStorageMax.split(" ");
          self.showDataStorage = String(datastorage_max[0]) + " " + "MB";
        }
      } else if (datastorage_max[1] === "GB") {
        _dataStorageMax = this.dataStorageMax.split(" ");
        self.showDataStorage = String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "GB";
      }
      console.log("Ddd", _dataStorageMax);

      // console.log("citizen---",this.storage_usage);
      // console.log("business---",this.storage_usage_biz);

      let _dataStorageUsage = this.storage_usage.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage);
        // console.log(max_storage);
        let total = (current_storage / max_storage) * 100;
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total) || 100;
        }
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total) || 100;
        }
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        if (max_storage === 0 && current_storage === 0) {
          return 0;
        }
        let total = (current_storage / max_storage) * 100;
        // console.log("t------",total);
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total);
        }
      }
    },
  },
  methods: {
    onScroll() {
      const showsize = window.innerWidth;
      this.isFooterDesktop = showsize > 600;
      this.isFooterMobile = showsize < 600;
    },
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      // console.log("convertstorageToByte",Math.ceil(databyte));
      return Math.ceil(databyte);
    },
    async getdata() {
      this.listpackage = [];
      let payload = {
        limit: "5",
        offset: "0",
        feature_extension: "main",
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + `/api/get_package`, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("res==========", response.data.result);
          console.log("id_mmm", this.id_mypackage);
          if (response.data.status === "OK") {
            for (var i = 0; i < response.data.result.length; i++) {
              let datapackage = {};
              datapackage["cre_dtm"] = response.data.result[i].cre_dtm;
              datapackage["feature_extension"] = response.data.result[i].feature_extension;
              datapackage["id"] = response.data.result[i].id;
              datapackage["package_name"] = response.data.result[i].package_name;
              datapackage["package_name_th"] = response.data.result[i].package_name_th;
              datapackage["package_price"] = response.data.result[i].package_price;
              datapackage["package_storage"] = response.data.result[i].package_storage;
              datapackage["package_number_user"] = response.data.result[i].number_user;
              datapackage["package_color"] =
                response.data.result[i].package_name === "Extra Small"
                  ? "#D9D9D9"
                  : response.data.result[i].package_name === "Small"
                  ? "#408298"
                  : response.data.result[i].package_name === "Medium"
                  ? "#3C6E6F"
                  : response.data.result[i].package_name === "Large"
                  ? "#274F63"
                  : response.data.result[i].package_name === "Extra Large"
                  ? "#353535"
                  : "";
              datapackage["dark"] = response.data.result[i].package_name === "Extra Small" ? false : true;
              datapackage["check_disable"] = response.data.result[i].id === this.id_mypackage ? true : false;
              this.listpackage.push(datapackage);
            }
            console.log("listpackage", this.listpackage);
            this.getpackage_addon();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    async getpackage_addon() {
      this.listpackage_addon = [];
      let payload = {
        limit: "5",
        offset: "0",
        feature_extension: "add_on",
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + `/api/get_package`, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            for (var i = 0; i < response.data.result.length; i++) {
              let datapackage_addon = {};
              datapackage_addon["cre_dtm"] = response.data.result[i].cre_dtm;
              datapackage_addon["feature_extension"] = response.data.result[i].feature_extension;
              datapackage_addon["id"] = response.data.result[i].id;
              datapackage_addon["package_name"] = response.data.result[i].package_name;
              datapackage_addon["package_name_th"] = response.data.result[i].package_name_th;
              datapackage_addon["package_price"] = response.data.result[i].package_price;
              datapackage_addon["package_storage"] = response.data.result[i].package_storage;
              datapackage_addon["package_number_user"] = response.data.result[i].number_user;
              this.listpackage_addon.push(datapackage_addon);
            }
            console.log("listpackage_addon", this.listpackage_addon);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    async fn_buypackage(data, type) {
      this.URL_payment = "";
      console.log("type", type);
      let payload;
      if (type === "main") {
        payload = {
          account_id: this.dataAccountId,
          data_id: this.dataAccountActive.business_info.business_id,
          type: "business",
          package_id: data.id,
          order_desc: "",
          type_package: "main",
        };
      } else {
        payload = {
          account_id: this.dataAccountId,
          data_id: this.dataAccountActive.business_info.business_id,
          type: "business",
          package_id: data.id,
          order_desc: "",
          type_package: "add_on",
        };
      }
      console.log("data", data);
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/payment_package_storage", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("res*", response.data.result);
          if (response.data.status === "OK") {
            console.log("response", response);
            this.URL_payment = response.data.result;
            this.gotopay();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    gotopay() {
      window.open(this.URL_payment, "_blank");
    },
    fn_checkpackage() {
      this.listpackage_current = [];
      this.listpackage_total = [];
      let payload = {
        // data_id: this.dataAccountId,
        data_id: this.dataAccountActive.business_info.business_id,
        type: "business",
      };
      console.log("payload", payload);
      console.log(this.dataBusinessProfile);
      console.log(this.dataAccountActive.business_info.business_id);
      // let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_package_current",
          payload
          //  {headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("res*", response.data.result);
          if (response.data.status === "OK") {
            console.log("response", response);
            for (var i = 0; i < response.data.result.length; i++) {
              if (response.data.result[i].status_package !== "") {
                let datapackage = {};
                datapackage["feature_extension"] = response.data.result[i].feature_extension;
                datapackage["package_id"] = response.data.result[i].package_id;
                datapackage["package_name"] = response.data.result[i].package_name;
                datapackage["package_price"] = response.data.result[i].package_price;
                datapackage["package_storage"] = response.data.result[i].package_storage;
                datapackage["status_package"] = response.data.result[i].status_package;
                this.listpackage_current.push(datapackage);
              }
            }

            for (var i = 0; i < response.data.details_package.length; i++) {
              if (response.data.result[i].status_package !== "") {
                let datatotalpackage = {};
                datatotalpackage["number_user"] = response.data.details_package[i].number_user;
                datatotalpackage["package_id"] = response.data.details_package[i].package_id;
                datatotalpackage["package_name"] = response.data.details_package[i].package_name;
                datatotalpackage["package_name_th"] = response.data.details_package[i].package_name_th;
                datatotalpackage["package_storage"] = response.data.details_package[i].package_storage;
                datatotalpackage["status_payment"] = response.data.details_package[i].status_payment;
                datatotalpackage["type_package"] = response.data.details_package[i].type_package;
                this.listpackage_total.push(datatotalpackage);
              }
            }
            console.log("this.listpackage_total", this.listpackage_total);
            this.id_mypackage = this.listpackage_current[0].package_id;
            console.log("++++++", this.id_mypackage);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    checkdefault(id) {
      // console.log(id);
      // this.mypackage = [];
      // for( let i = 0; i < this.listpackage_current.length; i++){
      //   if(this.listpackage_current[i].status_package !== ''){
      //     this.mypackage.push(this.listpackage_current[i])
      //   }
      // }
      // console.log("this.mypackage",this.mypackage);
      // for(let i=0; i < item.length; i++){
      //   console.log("item.id",item.id);
      //   console.log("this.mypackage.package_id",this.mypackage[0].package_id);
      //   if(item.id === this.mypackage[0].package_id){
      //     return true;
      //   }else{
      //     return false;
      //   }
      // }
    },
  },
  mounted() {
    console.log("dataStorageMax", this.dataStorageMax);
    console.log("storage_usage", this.storage_usage);
    this.fn_checkpackage();
    this.getdata();
    //  this.percentageStorage();
  },
};
</script>
<style></style>
